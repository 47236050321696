define('ember-redux/index', ['exports', 'ember-redux/connect', 'ember-redux/route'], function (exports, _connect, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'connect', {
    enumerable: true,
    get: function () {
      return _connect.default;
    }
  });
  Object.defineProperty(exports, 'route', {
    enumerable: true,
    get: function () {
      return _route.default;
    }
  });
});