define('ember-redux/ember-object', ['exports', 'ember-redux/core'], function (exports, _core) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = wrapEmberObject;
  function wrapEmberObject(stateToComputed, dispatchToActions, WrappedComponent) {

    return WrappedComponent.extend({
      redux: Ember.inject.service('redux'),

      init: function init() {
        _core.core.call(this, stateToComputed, dispatchToActions);
        this._super.apply(this, arguments);
      },
      didUpdateAttrs: function didUpdateAttrs() {
        this._super.apply(this, arguments);
        _core.update.call(this);
      },
      willDestroy: function willDestroy() {
        this._super.apply(this, arguments);
        _core.destroy.call(this);
      }
    });
  }
});