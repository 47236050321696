define("ember-cli-clipboard/templates/components/copy-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Lu8MPtUI",
    "block": "{\"symbols\":[\"@aria-label\",\"@classNames\",\"@class\",\"@disabled\",\"@title\",\"&attrs\",\"@clipboardText\",\"@clipboardTarget\",\"@clipboardAction\",\"@buttonType\",\"&default\"],\"statements\":[[8,\"button\"],[12,\"aria-label\",[22,1,[]]],[12,\"class\",[28,[\"copy-btn \",[22,3,[]],\" \",[22,2,[]]]]],[12,\"disabled\",[22,4,[]]],[12,\"title\",[22,5,[]]],[13,6],[12,\"data-clipboard-text\",[22,7,[]]],[12,\"data-clipboard-target\",[22,8,[]]],[12,\"data-clipboard-action\",[22,9,[]]],[12,\"type\",[27,\"if\",[[22,10,[]],[22,10,[]],\"button\"],null]],[9],[0,\"\\n  \"],[14,11],[0,\"\\n\"],[3,\"did-insert\",[[22,0,[\"setupElement\"]]]],[3,\"did-insert\",[[22,0,[\"registerClipboard\"]]]],[3,\"did-update\",[[22,0,[\"registerClipboard\"]]]],[3,\"will-destroy\",[[22,0,[\"destroyClipboard\"]]]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-cli-clipboard/templates/components/copy-button.hbs"
    }
  });

  _exports.default = _default;
});