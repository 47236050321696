define('ember-cli-pod-translations/mixins/localizable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (translations) {
    return Ember.Mixin.create({
      i18n: inject.service('i18n'),

      init: function init() {
        var _this = this;

        this._super.apply(this, arguments);

        var localizablePrefix = this._debugContainerKey.split(':').filter(function (segment) {
          return !!segment;
        }).pop();

        Object.keys(translations).forEach(function (locale) {
          _this.get('i18n').addTranslations(locale, _defineProperty({}, localizablePrefix, translations[locale]));
        });

        this.set('localizablePrefix', localizablePrefix);
      },
      localTranslation: function localTranslation(key, variables) {
        return this.get('i18n').t(this.get('localizablePrefix') + '.' + key, variables);
      },
      localRawTranslation: function localRawTranslation(key) {
        return this.get('i18n').t(this.get('localizablePrefix') + '.' + key).toString();
      }
    });
  };

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var inject = Ember.inject;
});