define("ember-add-calendar-button/templates/components/calendar-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "R5aNm+k7",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[27,\"hash\",null,[[\"google\",\"outlook\",\"ical\"],[[27,\"component\",[\"types/google-cal\"],[[\"event\",\"onClick\"],[[23,[\"event\"]],[27,\"action\",[[22,0,[]],\"onClick\"],null]]]],[27,\"component\",[\"types/outlook-cal\"],[[\"event\",\"onClick\"],[[23,[\"event\"]],[27,\"action\",[[22,0,[]],\"onClick\"],null]]]],[27,\"component\",[\"types/ical-cal\"],[[\"event\",\"onClick\"],[[23,[\"event\"]],[27,\"action\",[[22,0,[]],\"onClick\"],null]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"types/ical-cal\",null,[[\"event\",\"onClick\"],[[23,[\"event\"]],[27,\"action\",[[22,0,[]],\"onClick\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-add-calendar-button/templates/components/calendar-button.hbs"
    }
  });

  _exports.default = _default;
});