define("ember-basic-dropdown/templates/components/basic-dropdown-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bHzqagDK",
    "block": "{\"symbols\":[\"OptionalTag\",\"@defaultClass\",\"@renderInPlace\",\"@vPosition\",\"@hPosition\",\"@dir\",\"&attrs\",\"&default\",\"@overlay\",\"@htmlTag\",\"@dropdown\"],\"statements\":[[4,\"if\",[[22,11,[\"isOpen\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"ember-basic-dropdown-content-wormhole-origin\"],[9],[0,\"\\n\"],[5,\"maybe-in-element\",[],[[\"@destinationElement\",\"@renderInPlace\"],[[22,0,[\"destinationElement\"]],[22,3,[]]]],{\"statements\":[[4,\"if\",[[22,9,[]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"ember-basic-dropdown-overlay\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"let\",[[27,\"component\",[[27,\"ensure-safe-component\",[[27,\"-element\",[[27,\"or\",[[22,10,[]],\"div\"],null]],null]],null]],[[\"tagName\"],[[27,\"or\",[[22,10,[]],\"div\"],null]]]]],null,{\"statements\":[[0,\"        \"],[6,\"\",[[12,\"id\",[22,0,[\"dropdownId\"]]],[12,\"class\",[28,[\"ember-basic-dropdown-content ember-basic-dropdown-content--\",[22,5,[]],\"\\n            ember-basic-dropdown-content--\",[22,4,[]],\"\\n            \",[22,0,[\"animationClass\"]],[27,\"if\",[[22,3,[]],\" ember-basic-dropdown-content--in-place\"],null],\"\\n            \",[22,2,[]]]]],[12,\"dir\",[22,6,[]]],[13,7]],[[],[]],{\"statements\":[[0,\"\\n          \"],[14,8],[0,\"\\n        \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"id\",[22,0,[\"dropdownId\"]]],[11,\"class\",\"ember-basic-dropdown-content-placeholder\"],[9],[3,\"style\",[[27,\"hash\",null,[[\"display\"],[\"none\"]]]]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown-content.hbs"
    }
  });

  _exports.default = _default;
});