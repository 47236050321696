define("ember-add-calendar-button/components/types/ical-cal", ["exports", "ember-add-calendar-button/components/button-base", "ember-add-calendar-button/templates/components/types/ical-cal", "moment"], function (_exports, _buttonBase, _icalCal, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Fastboot support
  var doc = document || {
    URL: false
  };

  var _default = _buttonBase.default.extend({
    layout: _icalCal.default,
    attributeBindings: ['download'],
    download: Ember.computed('event', function () {
      var title = "".concat(Ember.get(this, 'event.title'), "-").concat(Ember.get(this, 'startTime').format('YYYY-MM-DD'));
      var safe = Ember.String.dasherize(title);
      return "".concat(safe, ".ics");
    }),
    generateHref: function generateHref(_ref) {
      var _ref$startTime = _ref.startTime,
          startTime = _ref$startTime === void 0 ? '' : _ref$startTime,
          _ref$endTime = _ref.endTime,
          endTime = _ref$endTime === void 0 ? '' : _ref$endTime,
          _ref$location = _ref.location,
          location = _ref$location === void 0 ? '' : _ref$location,
          _ref$title = _ref.title,
          title = _ref$title === void 0 ? '' : _ref$title,
          _ref$description = _ref.description,
          description = _ref$description === void 0 ? '' : _ref$description;

      if (!_moment.default.isMoment(startTime)) {
        startTime = (0, _moment.default)(startTime);
      }

      if (!_moment.default.isMoment(endTime)) {
        endTime = (0, _moment.default)(endTime);
      }

      var start = startTime.format('YYYYMMDDTHHmmss');
      var end = endTime.format('YYYYMMDDTHHmmss');
      var text = ['BEGIN:VCALENDAR', 'VERSION:2.0', 'BEGIN:VTIMEZONE', 'TZNAME:EST', 'TZID:America/New_York', 'END:VTIMEZONE', 'BEGIN:VEVENT', 'URL:' + doc.URL, "DTSTART;TZID=America/New_York:".concat(start), "DTEND;TZID=America/New_York:".concat(end), "SUMMARY:".concat(title), "DESCRIPTION:".concat(description), "LOCATION:".concat(location), 'END:VEVENT', 'END:VCALENDAR'];
      return encodeURI("data:text/calendar;charset=utf8,".concat(text.join('\n')));
    }
  });

  _exports.default = _default;
});