define("ember-power-calendar/components/power-calendar-multiple", ["exports", "ember-power-calendar/components/power-calendar", "ember-power-calendar-utils"], function (_exports, _powerCalendar, _emberPowerCalendarUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _powerCalendar.default.extend({
    daysComponent: 'power-calendar-multiple/days',
    _calendarType: 'multiple',
    // CPs
    selected: Ember.computed({
      get: function get() {
        return undefined;
      },
      set: function set(_, v) {
        return Array.isArray(v) ? v.map(_emberPowerCalendarUtils.normalizeDate) : v;
      }
    }),
    currentCenter: Ember.computed('center', function () {
      var center = this.get('center');

      if (!center) {
        center = (this.get('selected') || [])[0] || this.get('powerCalendarService').getDate();
      }

      return (0, _emberPowerCalendarUtils.normalizeDate)(center);
    }),
    // Actions
    actions: {
      select: function select(day, calendar, e) {
        var action = this.get("onSelect");

        if (action) {
          action(this._buildCollection(day), calendar, e);
        }
      }
    },
    // Methods
    _buildCollection: function _buildCollection(day) {
      var selected = this.get("publicAPI.selected") || [];
      var values = [];
      var index = -1;

      for (var i = 0; i < selected.length; i++) {
        if ((0, _emberPowerCalendarUtils.isSame)(day.date, selected[i], "day")) {
          index = i;
          break;
        }
      }

      if (index === -1) {
        values = [].concat(_toConsumableArray(selected), [day.date]);
      } else {
        values = selected.slice(0, index).concat(selected.slice(index + 1));
      }

      return (0, _emberPowerCalendarUtils.normalizeMultipleActionValue)({
        date: values
      });
    }
  });

  _exports.default = _default;
});