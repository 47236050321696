define('ember-apollo-client/utils/copy-with-extras', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = copyWithExtras;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var extraCopyProperties = ['__typename'];

  function copyWithExtras(obj, seen, copies) {
    var ret = void 0,
        loc = void 0,
        key = void 0;

    // primitive data types are immutable, just return them.
    if ((typeof obj === 'undefined' ? 'undefined' : _typeof(obj)) !== 'object' || obj === null) {
      return obj;
    }

    // avoid cyclical loops
    if ((loc = seen.indexOf(obj)) >= 0) {
      return copies[loc];
    }

    if (Array.isArray(obj)) {
      ret = obj.slice();

      loc = ret.length;

      while (--loc >= 0) {
        ret[loc] = copyWithExtras(ret[loc], seen, copies);
      }
    } else if (obj instanceof Date) {
      ret = new Date(obj.getTime());
    } else {
      ret = {};

      for (key in obj) {
        // support Null prototype
        if (!Object.prototype.hasOwnProperty.call(obj, key)) {
          continue;
        }

        // Prevents browsers that don't respect non-enumerability from
        // copying internal Ember properties
        if (key.substring(0, 2) === '__') {
          continue;
        }

        ret[key] = copyWithExtras(obj[key], seen, copies);
      }
      extraCopyProperties.forEach(function (propertyName) {
        return ret[propertyName] = obj[propertyName];
      });
    }

    seen.push(obj);
    copies.push(ret);

    return ret;
  }
});