define('ember-redux/enhancers/index', ['exports', 'redux'], function (exports, _redux) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var devtools = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : function (f) {
    return f;
  };
  exports.default = (0, _redux.compose)(devtools);
});