define('ember-redux/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var route = function route(funcs) {
    return function wrapWithRoute(IncomingRoute) {
      var WrappedRoute = IncomingRoute || Ember.Route;
      return WrappedRoute.extend({
        redux: Ember.inject.service('redux'),
        init: function init() {
          var redux = this.get('redux');
          var route = this;
          Object.keys(funcs).forEach(function (func) {
            route[func] = function () {
              for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                args[_key] = arguments[_key];
              }

              args.unshift(redux.dispatch.bind(redux));
              return funcs[func].apply(route, args);
            };
          });
          this._super.apply(this, arguments);
        }
      });
    };
  };

  exports.default = route;
});