define('ember-redux/reducers/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (state) {
    (false && Ember.warn('You haven\'t created a reducer yet. Place the root reducer in "app/reducers/index.js"', null, { id: 'ember-redux.default-reducer' }));


    return state;
  };
});