define('ember-apollo-client/index', ['exports', 'ember-apollo-client/-private/mixins/component-query-manager', 'ember-apollo-client/-private/mixins/object-query-manager', 'ember-apollo-client/-private/mixins/route-query-manager'], function (exports, _componentQueryManager, _objectQueryManager, _routeQueryManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.RouteQueryManager = exports.ObjectQueryManager = exports.ComponentQueryManager = exports.apolloObservableKey = undefined;
  exports.getObservable = getObservable;
  function getObservable(queryResult) {
    return queryResult._apolloObservable;
  }

  var apolloObservableKey = exports.apolloObservableKey = '_apolloObservable';

  exports.ComponentQueryManager = _componentQueryManager.default;
  exports.ObjectQueryManager = _objectQueryManager.default;
  exports.RouteQueryManager = _routeQueryManager.default;
});