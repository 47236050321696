define("ember-add-calendar-button/components/types/google-cal", ["exports", "ember-add-calendar-button/components/button-base", "ember-add-calendar-button/templates/components/types/google-cal", "moment"], function (_exports, _buttonBase, _googleCal, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _buttonBase.default.extend({
    layout: _googleCal.default,
    baseUrl: 'https://calendar.google.com/calendar/r/eventedit',
    generateHref: function generateHref(_ref) {
      var _ref$startTime = _ref.startTime,
          startTime = _ref$startTime === void 0 ? '' : _ref$startTime,
          _ref$endTime = _ref.endTime,
          endTime = _ref$endTime === void 0 ? '' : _ref$endTime,
          _ref$location = _ref.location,
          location = _ref$location === void 0 ? '' : _ref$location,
          _ref$title = _ref.title,
          title = _ref$title === void 0 ? '' : _ref$title,
          _ref$description = _ref.description,
          description = _ref$description === void 0 ? '' : _ref$description;

      if (!_moment.default.isMoment(startTime)) {
        startTime = (0, _moment.default)(startTime);
      }

      if (!_moment.default.isMoment(endTime)) {
        endTime = (0, _moment.default)(endTime);
      }

      var start = startTime.format('YYYYMMDDTHHmmss');
      var end = endTime.format('YYYYMMDDTHHmmss');
      var data = {
        dates: "".concat(start, "/").concat(end),
        details: description,
        text: title,
        location: location,
        ctz: 'America/New_York'
      };

      var string = this._toQString(data);

      return encodeURI("".concat(Ember.get(this, 'baseUrl'), "?").concat(string, "&sf=true&output=xml"));
    }
  });

  _exports.default = _default;
});