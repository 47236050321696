define("ember-stripe-elements/templates/components/stripe-card-expiry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CqjOfimh",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"role\",\"mount-point\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[23,[\"stripeElement\"]],[23,[\"stripeError\"]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-stripe-elements/templates/components/stripe-card-expiry.hbs"
    }
  });

  _exports.default = _default;
});