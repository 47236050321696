define('ember-redux/connect', ['exports', 'ember-redux/ember-object', 'ember-redux/es2015-class', 'ember-redux/es2019-class'], function (exports, _emberObject, _es2015Class, _es2019Class) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (stateToComputed) {
    var dispatchToActions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : function () {
      return {};
    };

    return function (IncomingComponent) {
      var WrappedComponent = IncomingComponent || Ember.Component;

      if (WrappedComponent.hasOwnProperty('extend')) {
        return (0, _emberObject.default)(stateToComputed, dispatchToActions, WrappedComponent);
      } else {
        if (true) {
          return (0, _es2019Class.default)(stateToComputed, dispatchToActions, WrappedComponent);
        }
        return (0, _es2015Class.default)(stateToComputed, dispatchToActions, WrappedComponent);
      }
    };
  };
});