define("@ember/test-waiters/index", ["exports", "ember-test-waiters"], function (_exports, _emberTestWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.keys(_emberTestWaiters).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (key in _exports && _exports[key] === _emberTestWaiters[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function get() {
        return _emberTestWaiters[key];
      }
    });
  });
});