define("ember-basic-dropdown/templates/components/basic-dropdown-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nBhHazrh",
    "block": "{\"symbols\":[\"OptionalTag\",\"@defaultClass\",\"@vPosition\",\"@hPosition\",\"@renderInPlace\",\"@dropdown\",\"&attrs\",\"&default\",\"@htmlTag\"],\"statements\":[[4,\"let\",[[27,\"component\",[[27,\"ensure-safe-component\",[[27,\"-element\",[[27,\"or\",[[22,9,[]],\"div\"],null]],null]],null]],[[\"tagName\"],[[27,\"or\",[[22,9,[]],\"div\"],null]]]]],null,{\"statements\":[[0,\"  \"],[6,\"\",[[12,\"class\",[28,[\"ember-basic-dropdown-trigger\\n      \",[27,\"if\",[[22,5,[]],\" ember-basic-dropdown-trigger--in-place\"],null],\"\\n      \",[27,\"if\",[[22,4,[]],[27,\"concat\",[\" ember-basic-dropdown-trigger--\",[22,4,[]]],null]],null],\"\\n      \",[27,\"if\",[[22,3,[]],[27,\"concat\",[\" ember-basic-dropdown-trigger--\",[22,3,[]]],null]],null],\"\\n      \",[22,2,[]]]]],[11,\"role\",\"button\"],[12,\"tabindex\",[27,\"unless\",[[22,6,[\"disabled\"]],\"0\"],null]],[12,\"data-ebd-id\",[28,[[22,6,[\"uniqueId\"]],\"-trigger\"]]],[12,\"aria-owns\",[28,[\"ember-basic-dropdown-content-\",[22,6,[\"uniqueId\"]]]]],[12,\"aria-expanded\",[28,[[22,6,[\"isOpen\"]]]]],[12,\"aria-disabled\",[27,\"if\",[[22,6,[\"disabled\"]],\"true\"],null]],[13,7]],[[],[]],{\"statements\":[[0,\"\\n    \"],[14,8],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown-trigger.hbs"
    }
  });

  _exports.default = _default;
});