define('ember-prop-types/utils/validators/one-of', ['exports', 'ember-prop-types/utils/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (ctx, name, value, def, logErrors, throwErrors) {
    var valueOptions = def.valueOptions;

    if (Ember.typeOf(valueOptions) !== 'array') {
      _logger.default.warn(ctx, 'PropTypes.oneOf() requires an array of values to be passed in as an argument', throwErrors);
      return false;
    }

    var valid = valueOptions.some(function (option) {
      return option === value;
    });

    if (!valid && logErrors) {
      _logger.default.warn(ctx, 'Property ' + name + ' is not one of: ' + valueOptions.join(', '), throwErrors);
    }

    return valid;
  };
});