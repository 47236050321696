define("ember-metrics/utils/object-transforms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.compact = compact;
  _exports.default = void 0;
  _exports.isPresent = isPresent;
  _exports.only = only;
  _exports.without = without;

  function compact(objectInstance) {
    var compactedObject = {};

    for (var key in objectInstance) {
      var value = objectInstance[key];

      if (Ember.isPresent(value)) {
        compactedObject[key] = value;
      }
    }

    return compactedObject;
  }

  function without(originalObj, keysToRemove) {
    var newObj = {};
    var allKeys = Object.keys(originalObj);
    allKeys.forEach(function (key) {
      if (keysToRemove.indexOf(key) === -1) {
        newObj[key] = originalObj[key];
      }
    });
    return newObj;
  }

  function only(originalObj, keysToRemain) {
    var newObj = {};
    var allKeys = Object.keys(originalObj);
    allKeys.forEach(function (key) {
      if (keysToRemain.indexOf(key) !== -1) {
        newObj[key] = originalObj[key];
      }
    });
    return newObj;
  }

  function isPresent(objectInstance) {
    var keys = Object.keys(objectInstance);
    return !!keys.length;
  }

  var _default = {
    compact: compact,
    without: without,
    only: only,
    isPresent: isPresent
  };
  _exports.default = _default;
});