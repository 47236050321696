define("ember-add-calendar-button/components/button-base", ["exports", "ember-add-calendar-button/templates/components/button-base", "moment"], function (_exports, _buttonBase, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _buttonBase.default,
    didRecieveAttrs: function didRecieveAttrs() {
      this._super.apply(this, arguments);

      (false && !(Ember.get(this, 'tagName') === 'a') && Ember.assert('`tagName` must be `a`', Ember.get(this, 'tagName') === 'a'));
    },
    attributeBindings: ['href', 'target'],
    tagName: 'a',
    target: '_blank',
    href: Ember.computed('event.{start,end,description,title,location}', function () {
      var event = Ember.get(this, 'event');
      var args = {
        startTime: Ember.get(this, 'startTime'),
        duration: Ember.get(this, 'duration'),
        endTime: Ember.get(this, 'endTime'),
        location: Ember.get(event, 'location'),
        description: Ember.get(event, 'description'),
        title: Ember.get(event, 'title')
      };
      return this.generateHref(args);
    }),

    /**
     * Just pass click event up the chain
     * @private
     */
    click: function click(event) {
      Ember.get(this, 'onClick')(event);
    },
    //Properties
    startTime: Ember.computed('event.start', function () {
      var start = Ember.get(this, 'event.start');
      return _moment.default.isMoment(start) ? start : (0, _moment.default)(start);
    }),
    endTime: Ember.computed('event.end', 'startTime', function () {
      var start = Ember.get(this, 'startTime');
      var end = Ember.get(this, 'event.end') || false;

      if (end) {
        return _moment.default.isMoment(end) ? end : (0, _moment.default)(end);
      }

      return start.add(90, 'minutes');
    }),
    duration: Ember.computed('event', 'startTime', 'endTime', function () {
      if (Ember.get(this, 'event.duration')) {
        return Ember.get(this, 'event.duration');
      }

      var start = Ember.get(this, 'startTime'),
          end = Ember.get(this, 'endTime');
      return start.diff(end);
    }),
    // Must Implment by exented component
    // Should return encodeURI()'d string
    // Args = { start, duration, location, description }
    generateHref: function generateHref() {
      (false && !(false) && Ember.assert('Please BYO method in subclass', false));
    },

    /**
     * Convert object to querystring
     * @private
     */
    _toQString: function _toQString(props) {
      var keys = Object.keys(props);
      var pairs = keys.map(function (x) {
        return x + '=' + props[x];
      });
      return pairs.join('&');
    }
  });

  _exports.default = _default;
});