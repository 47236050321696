define("ember-add-calendar-button/components/calendar-button", ["exports", "ember-add-calendar-button/templates/components/calendar-button"], function (_exports, _calendarButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Button = Ember.Component.extend({
    layout: _calendarButton.default,

    /*
     * Handler for conextual click actions
     */
    actions: {
      onClick: function onClick(event) {
        Ember.get(this, 'onClick')(event);
      }
    }
  });
  Button.reopenClass({
    positionalParams: ['event']
  });
  var _default = Button;
  _exports.default = _default;
});