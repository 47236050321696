define("ember-tooltips/templates/components/ember-tooltip-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0fJW/zYL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[22,0,[\"_awaitingTooltipElementRendered\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[22,0,[\"_shouldRenderContent\"]]],null,{\"statements\":[[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"        \"],[14,1,[[22,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,0,[\"text\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"in-element\",[[27,\"-clear-element\",[[22,0,[\"_renderElement\"]]],null]],[[\"guid\",\"guid\",\"nextSibling\"],[\"%cursor:0%\",\"%cursor:0%\",null]],{\"statements\":[[0,\"    \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[24,1]],null,{\"statements\":[[0,\"        \"],[14,1,[[22,0,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[22,0,[\"text\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-tooltips/templates/components/ember-tooltip-base.hbs"
    }
  });

  _exports.default = _default;
});