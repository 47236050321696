define('ember-redux/core', ['exports', 'redux'], function (exports, _redux) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.core = core;
  exports.update = update;
  exports.destroy = destroy;

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  /**
    Returns a list of keys that have different values between the two objects.
  
    @method changedKeys
    @return {Array} keys that have changed
    @private
    */
  function changedKeys(props, newProps) {
    return Object.keys(props).filter(function (key) {
      return props[key] !== newProps[key];
    });
  }

  /**
    Creates a read-only computed property for accessing redux state.
  
    @method computedReduxProperty
    @return {Function} an Ember computed property
    @private
    */
  function computedReduxProperty(key, getProps) {
    return Ember.computed({
      get: function get() {
        return getProps()[key];
      },
      set: function set() {
        (false && !(false) && Ember.assert('Cannot set redux property "' + key + '". Try dispatching a redux action instead.'));
      }
    });
  }

  /**
    Return an object of attrs passed to this Component.
  
    `Component.attrs` is an object that can look like this:
  
      {
        myAttr: {
          value: 'myValue'
        }
      }
  
    Ember provides that a `get` will return the value:
  
      this.get('myAttr') === 'myValue'
  
    @method getAttrs
    @private
    */
  function getAttrs(context) {
    var keys = Object.keys(context.attrs || {});
    return Ember.getProperties(context, keys);
  }

  /**
    Return a wrapped stateToComputed function that can be used
    regardless of whether the input function is a static selector
    or a factory.
  
    @method wrapStateToComputed
    @private
    */
  function wrapStateToComputed(stateToComputed) {
    return function () {
      var _stateToComputed;

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      var result = (_stateToComputed = stateToComputed).call.apply(_stateToComputed, [this].concat(args));
      if (typeof result === 'function') {
        var _stateToComputed2;

        stateToComputed = result;
        return (_stateToComputed2 = stateToComputed).call.apply(_stateToComputed2, [this].concat(args));
      }
      return result;
    };
  }

  function core(stateToComputed, dispatchToActions) {
    var _this = this;

    var redux = this.get('redux');

    if (stateToComputed) {
      var wrappedStateToComputed = wrapStateToComputed(stateToComputed);
      var getProps = function getProps() {
        return wrappedStateToComputed.call(_this, redux.getState(), getAttrs(_this));
      };

      var props = getProps();

      Object.keys(props).forEach(function (key) {
        Ember.defineProperty(_this, key, computedReduxProperty(key, function () {
          return props;
        }));
      });

      this._handleChange = function () {
        var newProps = getProps();
        if (props === newProps) return;

        var notifyProperties = changedKeys(props, newProps);

        props = newProps;

        if (notifyProperties.length > 0) {
          Ember.run.join(function () {
            notifyProperties.forEach(function (name) {
              return _this.notifyPropertyChange(name);
            });
          });
        }
      };

      this.unsubscribe = redux.subscribe(function () {
        _this._handleChange();
      });
    }

    if (typeof dispatchToActions === 'function') {
      this.actions = Object.assign({}, this.actions, dispatchToActions.call(this, redux.dispatch.bind(redux)));
    }

    if ((typeof dispatchToActions === 'undefined' ? 'undefined' : _typeof(dispatchToActions)) === 'object') {
      this.actions = Object.assign({}, this.actions, (0, _redux.bindActionCreators)(dispatchToActions, redux.dispatch.bind(redux)));
    }
  }

  function update() {
    if (this._handleChange) {
      this._handleChange();
    }
  }

  function destroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }
});