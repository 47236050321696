define("ember-power-calendar-utils/index", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.add = add;
  exports.formatDate = formatDate;
  exports.startOf = startOf;
  exports.endOf = endOf;
  exports.weekday = weekday;
  exports.isoWeekday = isoWeekday;
  exports.getWeekdaysShort = getWeekdaysShort;
  exports.getWeekdaysMin = getWeekdaysMin;
  exports.getWeekdays = getWeekdays;
  exports.isAfter = isAfter;
  exports.isBefore = isBefore;
  exports.isSame = isSame;
  exports.isBetween = isBetween;
  exports.diff = diff;
  exports.normalizeDate = normalizeDate;
  exports.normalizeRangeActionValue = normalizeRangeActionValue;
  exports.normalizeMultipleActionValue = normalizeMultipleActionValue;
  exports.normalizeCalendarDay = normalizeCalendarDay;
  exports.withLocale = withLocale;
  exports.normalizeCalendarValue = normalizeCalendarValue;
  exports.normalizeDuration = normalizeDuration;
  exports.getDefaultLocale = getDefaultLocale;
  exports.localeStartOfWeek = localeStartOfWeek;
  exports.startOfWeek = startOfWeek;
  exports.endOfWeek = endOfWeek;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function add(date, quantity, unit) {
    return (0, _moment.default)(date).add(quantity, unit).toDate();
  }

  function formatDate(date, format) {
    var locale = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

    if (locale) {
      return withLocale(locale, function () {
        return (0, _moment.default)(date).format(format);
      });
    } else {
      return (0, _moment.default)(date).format(format);
    }
  }

  function startOf(date, unit) {
    return (0, _moment.default)(date).startOf(unit).toDate();
  }

  function endOf(date, unit) {
    return (0, _moment.default)(date).endOf(unit).toDate();
  }

  function weekday(date) {
    return (0, _moment.default)(date).weekday();
  }

  function isoWeekday(date) {
    return (0, _moment.default)(date).isoWeekday();
  }

  function getWeekdaysShort() {
    return _moment.default.weekdaysShort();
  }

  function getWeekdaysMin() {
    return _moment.default.weekdaysMin();
  }

  function getWeekdays() {
    return _moment.default.weekdays();
  }

  function isAfter(date1, date2) {
    return (0, _moment.default)(date1).isAfter(date2);
  }

  function isBefore(date1, date2) {
    return (0, _moment.default)(date1).isBefore(date2);
  }

  function isSame(date1, date2, unit) {
    return (0, _moment.default)(date1).isSame(date2, unit);
  }

  function isBetween(date, start, end, unit, inclusivity) {
    return (0, _moment.default)(date).isBetween(start, end, unit, inclusivity);
  }

  function diff(date1, date2) {
    return (0, _moment.default)(date1).diff(date2);
  }

  function normalizeDate(dateOrMoment) {
    if (dateOrMoment === undefined || dateOrMoment === null || dateOrMoment instanceof Date) {
      return dateOrMoment;
    } else {
      return dateOrMoment.toDate();
    }
  }

  function normalizeRangeActionValue(val) {
    return {
      date: val.date,
      moment: {
        start: val.date.start ? (0, _moment.default)(val.date.start) : val.date.start,
        end: val.date.end ? (0, _moment.default)(val.date.end) : val.date.end
      }
    };
  }

  function normalizeMultipleActionValue(val) {
    return {
      date: val.date,
      moment: val.date ? val.date.map(function (e) {
        return (0, _moment.default)(e);
      }) : val.date
    };
  }

  function normalizeCalendarDay(day) {
    day.moment = (0, _moment.default)(day.date);
    return day;
  }

  function withLocale(locale, fn) {
    var returnValue = void 0;
    if (locale) {
      var previousLocale = _moment.default.locale();
      _moment.default.locale(locale);
      returnValue = fn();
      _moment.default.locale(previousLocale);
    } else {
      returnValue = fn();
    }
    return returnValue;
  }

  function normalizeCalendarValue(value) {
    if (value) {
      return { date: value.date, moment: value.date ? (0, _moment.default)(value.date) : undefined };
    }
    return { date: undefined, moment: undefined };
  }

  function normalizeDuration(value) {
    if (value === null) {
      return null;
    }
    if (_moment.default.isDuration(value)) {
      return value.asMilliseconds();
    }
    if (typeof value === "number") {
      return value;
    }
    if (typeof value === "string") {
      var _value$match = value.match(/(\d+)(.*)/),
          _value$match2 = _slicedToArray(_value$match, 3),
          quantity = _value$match2[1],
          units = _value$match2[2];

      units = units.trim() || "days";
      return _moment.default.duration(parseInt(quantity, 10), units).asMilliseconds();
    }
  }

  function getDefaultLocale() {
    return _moment.default.locale();
  }

  function localeStartOfWeek(locale) {
    var now = new Date();
    var day = withLocale(locale, function () {
      return formatDate(startOf(now, 'week'), 'dddd');
    });
    var idx = withLocale(locale, getWeekdays).indexOf(day);
    return idx >= 0 ? idx : 0;
  }

  function startOfWeek(day, startOfWeek) {
    while (isoWeekday(day) % 7 !== startOfWeek) {
      day = add(day, -1, "day");
    }
    return day;
  }

  function endOfWeek(day, startOfWeek) {
    var eow = (startOfWeek + 6) % 7;
    while (isoWeekday(day) % 7 !== eow) {
      day = add(day, 1, "day");
    }
    return day;
  }
});