define('ember-redux/services/redux', ['exports', 'redux', 'ember-redux/reducers/index', 'ember-redux/enhancers/index', 'ember-redux/middleware/index'], function (exports, _redux, _index, _index2, _index3) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  // Handle "classic" middleware exports (i.e. an array), as well as the hash option
  var extractMiddlewareConfig = function extractMiddlewareConfig(mc) {
    (false && !(Ember.isArray(mc) || mc.middleware) && Ember.assert('Middleware must either be an array, or a hash containing a `middleware` property', Ember.isArray(mc) || mc.middleware));

    return Ember.isArray(mc) ? { middleware: mc } : mc;
  };

  var createStore = _redux.default.createStore,
      applyMiddleware = _redux.default.applyMiddleware,
      compose = _redux.default.compose;


  var makeStoreInstance = function makeStoreInstance(_ref) {
    var middlewares = _ref.middlewares,
        reducers = _ref.reducers,
        enhancers = _ref.enhancers;

    var _extractMiddlewareCon = extractMiddlewareConfig(middlewares),
        middleware = _extractMiddlewareCon.middleware,
        _extractMiddlewareCon2 = _extractMiddlewareCon.setup,
        setup = _extractMiddlewareCon2 === undefined ? function () {} : _extractMiddlewareCon2;

    var createStoreWithMiddleware = compose(applyMiddleware.apply(undefined, _toConsumableArray(middleware)), enhancers)(createStore);
    var store = createStoreWithMiddleware(reducers);
    setup(store);
    return store;
  };

  exports.default = Ember.Service.extend({
    middlewares: _index3.default,
    reducers: _index.default,
    enhancers: _index2.default,
    makeStoreInstance: makeStoreInstance,
    init: function init() {
      var enhancers = Ember.get(this, 'enhancers');
      var reducers = Ember.get(this, 'reducers');
      var middlewares = Ember.get(this, 'middlewares');
      this.store = this.makeStoreInstance({ middlewares: middlewares, reducers: reducers, enhancers: enhancers });
      this._super.apply(this, arguments);
    },
    getState: function getState() {
      return this.store.getState();
    },
    dispatch: function dispatch(action) {
      return this.store.dispatch(action);
    },
    subscribe: function subscribe(func) {
      return this.store.subscribe(func);
    },
    replaceReducer: function replaceReducer(nextReducer) {
      return this.store.replaceReducer(nextReducer);
    }
  });
});