define('ember-apollo-client/-private/mixins/object-query-manager', ['exports', 'ember-apollo-client/-private/mixins/base-query-manager'], function (exports, _baseQueryManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_baseQueryManager.default, {
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      this.get('apollo').unsubscribeAll(false);
    }
  });
});