define('ember-prop-types/utils/validators/bool', ['exports', 'ember-prop-types/utils/logger'], function (exports, _logger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (ctx, name, value, def, logErrors, throwErrors) {
    var valid = Ember.typeOf(value) === 'boolean';

    if (!valid && logErrors) {
      _logger.default.warn(ctx, 'Expected property ' + name + ' to be a boolean but instead got: ' + Ember.typeOf(value), throwErrors);
    }

    return valid;
  };
});