define('ember-apollo-client/-private/mixins/base-query-manager', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    apolloService: Ember.inject.service('apollo'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('apollo', this.get('apolloService').createQueryManager());
    }
  });
});